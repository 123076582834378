import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';

import Introduction from '../components/Homepage/Introduction';
import HerstellerTeaser from '../components/Homepage/HerstellerTeaser';
import KeyFacts from '../components/Homepage/KeyFacts';
import Awards from '../components/Common/Awards';
import Boxes from '../components/Homepage/Boxes';
import Carousel from '../components/Common/Carousel';

const IndexPage = ({ data, location }) => {
  const { manufacturers, page, site, news } = data;
  const { siteMetadata } = site;
  const { fact1, fact2, fact3, teaser, boxes, slides, metadata, opengraph } = page;

  return (
    <DefaultLayout>
      <Carousel slides={slides} />
      <div className="wrap">
        <Boxes boxes={boxes} news={news} />
        <Awards style={{ margin: '0' }} />
      </div>
      <HerstellerTeaser manufacturers={manufacturers.edges} />
      <div className="wrap">
        <Introduction data={teaser} />
        <KeyFacts data={{ fact1, fact2, fact3 }} />
      </div>
    </DefaultLayout>
  );
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query HomepageData($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    page: cockpitRegionStartseite(lang: { eq: $language }) {
      id
      fact1 {
        line1
        number
        line2
      }
      fact2 {
        line1
        number
        line2
      }
      fact3 {
        line1
        number
        line2
      }
      teaser {
        title
        text
        link
        linkLabel
      }
      boxes {
        id
        title
        teaser
        link
        alttext
        actionlabel
        image {
          childImageSharp {
            id
            gatsbyImageData(width: 340, aspectRatio: 2, quality: 90, placeholder: TRACED_SVG)
          }
        }
      }
      slides {
        id
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 2000, height: 800, layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
        text_line_1
        text_line_2
        mobile_text_line_1
        mobile_text_line_2
        text_color
        position
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    manufacturers: allCockpitHersteller(filter: { published: { eq: true }, lang: { eq: $language } }) {
      edges {
        node {
          name
          slug
          logopng {
            publicURL
          }
          logosvg {
            publicURL
          }
          logoai {
            publicURL
          }
          logojpg {
            publicURL
          }
        }
      }
    }
    news: allCockpitNews(
      filter: { sites: { in: "ezagrar.at" }, published: { eq: true }, lang: { eq: $language } }
      sort: { fields: cockpitCreated, order: DESC }
    ) {
      edges {
        node {
          id
          title
          teaser
          date
          customurl
          expirationdate
          teaserimage {
            childImageSharp {
              gatsbyImageData(height: 180, aspectRatio: 2)
            }
          }
          images {
            alttext
            image {
              publicURL
              publicURL
              childImageSharp {
                gatsbyImageData(height: 180, aspectRatio: 2)
              }
            }
          }
        }
      }
    }
  }
`;
